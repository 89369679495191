//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['contact_text'],
	methods: {
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	},
	mounted() {},
	computed: {
		description() {
			if (this.blok.text && this.blok.text.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		}
	}
}
