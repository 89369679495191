import { render, staticRenderFns } from "./UserBookmarks.vue?vue&type=template&id=595a95be&"
import script from "./UserBookmarks.vue?vue&type=script&lang=js&"
export * from "./UserBookmarks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Cards: require('/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022549/components/Cards.vue').default})
