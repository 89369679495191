import { render, staticRenderFns } from "./rolling-office.vue?vue&type=template&id=9adc960a&"
import script from "./rolling-office.vue?vue&type=script&lang=js&"
export * from "./rolling-office.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GDPR: require('/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022549/components/GDPR.vue').default,GlobalHeader: require('/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022549/components/GlobalHeader.vue').default,GlobalFooter: require('/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022549/components/GlobalFooter.vue').default,GlobalModal: require('/home/webadmin/sites/bnind.com/bnfe/releases/20240605T022549/components/GlobalModal.vue').default})
